import styled from 'styled-components';


// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
export const ContainerHeaderTV = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ContainerNameTV = styled.h1`
  color: #D1D1D1;
  margin: 0 0 0 1.75rem;
  font-family: "Jost", sans-serif;
  font-size: 96px;
  font-weight: 500;
  line-height: 112px;
  padding-top: 1.5rem;
`;



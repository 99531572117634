import { ChangeEvent, useEffect, useState } from "react";
import { useParams, useHistory } from 'react-router-dom';
import * as Styled from "./styled";

import Logo from "../../components/Logo";
import CellService from "../../services/cell.service";
import { parseError } from "../../config/error-handler";
import SmallCard from "../../components/SmallCard";
import Clock from "../../components/time";
import DateDisplay from "../../components/date";
import SignInForm from "../../components/forms/signIn";
import Checkbox from "../../components/checklist";
import SmallCardMeta from "../../components/smallCardMeta";
import ChecklistService from '../../services/checklist.service';
import Modal from "../../components/modalLogin";
import ConfirmationModalLogin from "../../components/modalLoginConfirm"; 
import SmallCardCell from "../../components/SmallCardCell";
import routeParams from "../../models/routeParams";

const Login = () => {
  const { workstationId, cellId } = useParams<routeParams>();
  const cellService = new CellService();
  const history = useHistory();
  const [postoTrabalho, setPostoTrabalho] = useState(1);
  const [celula, setCelula] = useState(1);
  const [meta, setMeta] = useState(0);
  const [metaConfirmada, setMetaConfirmada] = useState(false);
  const [horarioConfirmado, setHorarioConfirmado] = useState(false);
  const [postoDeTrabalhoOrganizado, setPostoDeTrabalhoOrganizado] = useState(false);
  const [postoDeTrabalhoAbastecido, setpostoDeTrabalhoAbastecido] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [uncheckedItems, setUncheckedItems] = useState<string[]>([]);
  const selectedChecklistLabels = ['Meta Confirmada', 'Horário Confirmado', 'Posto de Trabalho Organizado', 'Posto de Trabalho Abastecido'];

  useEffect(() => {
    setPostoTrabalho(Number(workstationId));
    setCelula(Number(cellId));
  }, [workstationId, cellId]);
  
  useEffect(() => {
    cellService
      .getFlatWorkstation(celula, postoTrabalho)
      .then((response) => {
        setMeta(response.goal);
      })
      .catch((error) => {
        setMeta(0);
        console.error("Erro ao buscar os dados da célula:", parseError(error));
      });
  }, [celula, postoTrabalho]);

  const handleSignIn = async () => {
    const checklistService = new ChecklistService();
    
    const selectedChecklistItems = {
      metaConfirmada,
      horarioConfirmado,
      postoDeTrabalhoOrganizado,
      postoDeTrabalhoAbastecido,
    };
    const uncheckedItemsList = Object.values(selectedChecklistItems);

    if (uncheckedItemsList.includes(false)) {
      setUncheckedItems(selectedChecklistLabels.filter((_, index) => !uncheckedItemsList[index]));
      setIsModalVisible(true);
      return;
    }
    
    try {
      await checklistService.sendChecklistItems(selectedChecklistItems, celula, postoTrabalho);
      history.push(`/${workstationId}/${cellId}`);
      window.location.reload();
    } catch (error) {
      console.error('Erro ao enviar os itens do checklist:', error);
    }
  };

  const uncheckedItemsText = uncheckedItems.map((item, index) => <li key={index}>✅ {`${item}`}</li>);

  return (
    <Styled.Container>
      <Styled.RowDataHora>
        <Styled.Left>
          <DateDisplay />
        </Styled.Left>
        <Styled.Right>
          <Clock />
        </Styled.Right>
      </Styled.RowDataHora>
      <Logo />
      <Styled.Card>
        <Styled.Row>
          <Styled.Left>
            <SmallCard label="Posto de trabalho" value={postoTrabalho.toString()} />
            {/* labelAux é um texto em branco para ajustar o layout dos cards */}
            <SmallCardCell label="Célula" labelAux=" texto branco " value={celula.toString()} />
            <SmallCardMeta label="Meta" value={(meta).toString()} />
          </Styled.Left>
          <Styled.RigthLogin>
            <Styled.NameTitleChecklist>Checklist</Styled.NameTitleChecklist>
            <Checkbox
              label="Posto de Trabalho Organizado"
              checked={postoDeTrabalhoOrganizado}
              onChange={(isChecked: boolean) => setPostoDeTrabalhoOrganizado(isChecked)}
            />
            <Checkbox
              label="Posto de Trabalho Abastecido"
              checked={postoDeTrabalhoAbastecido}
              onChange={(isChecked: boolean) => setpostoDeTrabalhoAbastecido(isChecked)}
            />
            <Checkbox
              label="Meta Confirmada"
              checked={metaConfirmada}
              onChange={(isChecked: boolean) => setMetaConfirmada(isChecked)}
            />
            <Checkbox
              label="Horário Confirmado"
              checked={horarioConfirmado}
              onChange={(isChecked: boolean) => setHorarioConfirmado(isChecked)}
            />
          </Styled.RigthLogin>
        </Styled.Row>
      </Styled.Card>
      <Styled.CardLogin>
        <SignInForm 
          onSuccess={handleSignIn}
          workstationId={workstationId}
          cellId={cellId}
          checklist={{ 
            metaConfirmada, 
            horarioConfirmado, 
            postoDeTrabalhoOrganizado, 
            postoDeTrabalhoAbastecido, 
            selectedChecklistLabels,
            setUncheckedItems,
            setIsModalVisible 
          }} 
        />
        <Modal
          headerText="Confirme os itens do checklist"
          isShown={isModalVisible}
          hide={() => setIsModalVisible(false)}
          modalContent={
            <ConfirmationModalLogin
              onConfirm={() => setIsModalVisible(false)}
              message={<ul>{uncheckedItemsText}</ul>}
            />
          }
        />
      </Styled.CardLogin>
    </Styled.Container>
  );
};
export default Login;

import styled from 'styled-components';


export const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // usado para alinhar os itens no centro devido a diferença
    // de tamanho entre os campos
    // justify-content: space-between;
    // juntamente com margin-right: 14rem; em FieldValueTVBold
    align-items: start;
    font-weight: 600;
    color: #fff;
    margin-top: 0.8rem;
    margin-left: 2rem;
    // espaçamento entre a meta da fabrica e os item de produção
    // margin-bottom: 12rem;
    margin-bottom: 14rem;
`;

export const FieldTitleTVBold = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    font-size: 32px;
    font-weight: 600;
    @font-face {
        font-family: 'Lato', Sans-serif;
        src: url('/fonts/Lato/Lato-Regular.ttf');
    }
    margin-top: 1rem;
`;

export const FieldValueTVBold = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    @font-face {
        font-family: 'Lato', Sans-serif;
        src: url('/fonts/Lato/Lato-Regular.ttf');
    }
    font-size: 32px;
    font-weight: 700;
    margin-top: 1rem;
    margin-right: 14rem;
`;
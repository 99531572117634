import styled from 'styled-components';


// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------

export const Box = styled.div`
  border: 1px solid rgba(72,46,25,0.5);
  border-radius: 8px;
  height: 75px;
  max-width: 185px;
  padding: 10px;
  margin-bottom: 20px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: ;
  justify-content: space-between;

  @media(width < 400px) {
    max-width: 90px;
  }
`;

export const BoxLabel = styled.h3`
  color: #482E19;
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  font-family: 'Lato', 'sans-serif';
`;

export const BoxLabelAux = styled.h3`
  color: white;
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  font-family: 'Lato', 'sans-serif';
`;

export const BoxValue = styled.p`
  margin-top: -20px;
  font-size: 36px;
  font-weight: 700;
  font-family: 'Lato', 'sans-serif';
  line-height: 43px;
  text-align: right;
`;

import styled from 'styled-components'

export const Wrapper = styled.div`
    width 80%;
    @media(min-width: 768px) {
    width: 60%;
    }
    @media(min-width: 1024px) {
    width: 40%;
    }
`;

export const ConfirmationButtons = styled.div`
	display: flex;
	justify-content: center;
  padding: 1.5rem;
`;

export const Message = styled.div`
	font-size: 24px;
  font-weight: 400;;
  @font-face {
    font-family: 'Lato', sans-serif;
    src: url('/fonts/Lato-Regular.ttf');
  }
  ul {
    list-style-type: none;
    li {
      margin-bottom: 1rem;
    }
  }
  padding: 1rem;
`;

export const YesButton = styled.button`
	width: 11rem;
  height: 3rem;
  color: #80764B;
  background-color: #fff;
  font-size: 1rem;
  font-weight: 600;
  border: 2px solid #80764B;
  border-radius: 8px;
  margin-left: auto;
  margin-right: auto;
  align-self: center;
  cursor: pointer;
  
  &:hover {
    background-color: #80764B;
    color: #fff;
    transition: 0.3s;
  }
`;

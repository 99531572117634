import styled from 'styled-components';


// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
export const Box = styled.div`
  border: 1px solid rgba(72,46,25,0.5);
  border-radius: 8px;
  height: 75px;
  max-width: 185px;
  padding: 10px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: ;
  justify-content: space-between;

`;

export const BoxLabel = styled.h3`
  color: #482E19;
  margin: 0;
  font-size: 20px;
  font-weight: 900;
  font-family: 'Lato', 'sans-serif';
`;

export const BoxValue = styled.p`
  margin: 0;
  color: #482E19;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: right;
  font-family: 'Lato', 'sans-serif';
`;

import { toast } from "react-toastify";
import Service from "./service";
import WorkSessionDto from "../dto/worksession.dto";


/**
 * Responsible for managing cell requests. 
 */
class CellService extends Service {
  
  // --------------------------------------------------------------------------
  //         Constructor
  // --------------------------------------------------------------------------
  constructor() {
    super();
  }


  // --------------------------------------------------------------------------
  //         Methods
  // --------------------------------------------------------------------------
  public async getWorkstation(cellId: number, workstationId: number): Promise<any> {
    const response = await this.remoteRequest.get(`cell/${cellId}/workstation/${workstationId}`);    
    
    return response.data;
  }

  public async getFlatWorkstation(cellId: number, workstationId: number): Promise<any> {
    const response = await this.remoteRequest.get(`cell/${cellId}/workstation/${workstationId}/flat`);    
    
    return response.data;
  }

  public async addStopReason(cellId: number, workstationId: number, stopReasonId: number): Promise<any> {
    const response = await this.remoteRequest.post(`cell/${cellId}/workstation/${workstationId}/addStopReason/${stopReasonId}`);    
    
    return response.data;
  }

  public async closeStopReason(cellId: number, workstationId: number): Promise<any> {
    const response = await this.remoteRequest.post(`cell/${cellId}/workstation/${workstationId}/closeStopReason`);    
    
    return response.data;
  }

  public async getWorkedTime(cellId: number): Promise<any> {
    const response = await this.remoteRequest.get(`/workedTime/${cellId}`);    
    
    return response.data;
  }

}

export default CellService;

import React, { FunctionComponent } from 'react';
import { YesButton, Message, ConfirmationButtons } from './styled';

interface ConfirmationModalProps {
    onConfirm: () => void;
    message: string | JSX.Element;
}

const ConfirmationModalLogin: FunctionComponent<ConfirmationModalProps> = (props) => {
    const handleConfirm = () => {
        props.onConfirm();
    };

    return (
        <React.Fragment>
            <Message>{props.message}</Message>
            <ConfirmationButtons>
                <YesButton onClick={props.onConfirm}>OK</YesButton>
            </ConfirmationButtons>
        </React.Fragment>
    );
}

export default ConfirmationModalLogin;
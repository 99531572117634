import styled from 'styled-components'

export const LogoContainerTV = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 13rem;
    margin-bottom: 2rem;
    opacity: 0.125;

    img {
        width: 320px; 
        height: auto;
    }
`;
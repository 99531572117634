import styled from 'styled-components'


// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
export const Container = styled.div`
  background-color: #80764B;
`;

export const NameTitle = styled.h1`
  color: white;
  padding: 5px;
`;

export const NameTitleChecklist = styled.h1`
  color: #80764B;
  @font-face {
    font-family: 'Jost', sans-serif;
    src: url('/fonts/Jost-VariableFont_wght.ttf');
  font-size: 32px;
  font-weight: 500;
  padding: 5px;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 50px;
  padding-bottom: 20px;
  margin-bottom: 25px; 
  border-radius: 16px;
  margin-top: 25px;
  width: 650px;
`;

export const CardLogin = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
  margin-top: 25px; 
  margin-bottom: 25px; 
  border-radius: 16px;
  max-width: 700px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
`;

export const RowDataHora = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
  margin: 0 auto;
  max-width: 860px;
`;

export const Right = styled.div`
  flex: 1;
`;

export const RightColab = styled.div`
  flex: 2;
  flex-direction: row;
`;

export const RigthLogin = styled.div`
  flex: 2 2 13%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  padding-left: 44px;
  padding-right: 20px;
  padding-top: 0px;
  padding-bottom: 0px;
`;

export const Left = styled.div`
  flex: 1;
`;

export const LeftColab = styled.div`
  flex: 2;
`;


import { toast } from "react-toastify";
import Service from "./service";
import UserDto from "../dto/user.dto";


/**
 * Responsible for managing authentication requests. 
 */
class AuthService extends Service {
  
  // --------------------------------------------------------------------------
  //         Constructor
  // --------------------------------------------------------------------------
  constructor() {
    super();
  }


  // --------------------------------------------------------------------------
  //         Methods
  // --------------------------------------------------------------------------
  public async makeLogin( email: string, password: string): Promise<boolean> {
    let success = false;
    
    try {
        const response = await this.remoteRequest.post(`/login`, {
            email,
            password
        });

        if (response.headers.authorization) {
            localStorage.setItem('x_auth_token', response.headers.authorization.substring(7));
            localStorage.setItem('isAuthenticated', "true");
            success = true;
        }
    } 
    catch (error: any) {
        toast.error(error.response.data.message);
    }

    return success;
  }

  public async makeLogout(workstationId: string, cellId: string): Promise<void> {
    await this.remoteRequest.post('/auth/logout');
    
    localStorage.removeItem('x_auth_token');
    localStorage.removeItem('isAuthenticated');
    window.location.href = `/#/login/${workstationId}/${cellId}/`;
  }

  public async getAuthenticatedUser(): Promise<UserDto> {
    const response = await this.remoteRequest.get('/auth');
    return response.data;
  }
}

export default AuthService;

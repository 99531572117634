import { TextField } from '@mui/material';
import styled from 'styled-components'


// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
export const Container = styled.div`
  background-color: #80764B;
`;

export const RowDataHora = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
  margin: 0 auto;
  max-width: 860px;
`;

export const RowButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  max-width: 860px;
`;

export const RowButtonSair = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  padding-top: -25px;
  padding-bottom: 15px;
`;
  
export const Left = styled.div`
  flex: 1;
  padding: 0;
  margin: 0;
`;

export const Right = styled.div`
  flex: 1;
  padding: 0;
  margin: 0;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 50px;
  padding-bottom: 20px;
  margin-bottom: 25px; 
  border-radius: 16px;
  width: 650px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
`;

export const ResponsiveRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

export const CenteredColumn = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
`;

export const RightFabrica = styled.div`
  flex: 3;
  flex-direction: row;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 22px;
  margin-top: 1rem;
  margin-left: 5rem;
`;

export const RightColab = styled.div`
  flex: 2;
  flex-direction: row;
`;

export const LeftFabrica = styled.div`
  flex: 1;
  flex-direction: row;
  margin-top: 1rem;
`;

export const InputDefault = styled(TextField)`
  width: 100%;
  font-family: lato, sans-serif;
  color: #F4F1E4;
  z-index: 0;
  background-color: rgb(244,241,228,0.5);
  
  .MuiOutlinedInput-notchedOutline {
    min-height: 40px;
    border-width: 1px;
    border: 1px solid rgba(72,46,25,0.5);
    border-radius: 8px;
  }
`;
import React, {FunctionComponent, useEffect } from "react";
import FocusLock from "react-focus-lock";
import ReactDOM from "react-dom";
import {Wrapper, Header,  StyledModal, HeaderText, Content, Backdrop} from "./styled";

interface ModalProps {
    isShown: boolean;
    hide: () => void;
    modalContent: JSX.Element;
    headerText: string;
}

const Modal: FunctionComponent<ModalProps> = ({
    isShown,
    hide,
    modalContent,
    headerText
}) => {
    const onKeyDown = (event: KeyboardEvent) => {
        if (event.keyCode === 27 && isShown) {
            hide();
        }
    };

    useEffect(() => {
        isShown
            ? (document.body.style.overflow = "hidden")
            : (document.body.style.overflow = "unset");
        document.addEventListener("keydown", onKeyDown, false);
        return () => {
            document.removeEventListener("keydown", onKeyDown, false);
        };
    }, [isShown]);

    const modalLogin = (
        <React.Fragment>
            <Backdrop onClick={hide} />
            <FocusLock>
                <Wrapper
                    aria-modal
                    aria-labelledby={headerText}
                    tabIndex={-1}
                    role="dialog"
                >
                    <StyledModal>
                        <Header>
                            <HeaderText>{headerText}</HeaderText>
                        </Header>
                        <Content>{modalContent}</Content>
                    </StyledModal>
                </Wrapper>
            </FocusLock>
        </React.Fragment>
    );

    return isShown ? ReactDOM.createPortal(modalLogin, document.body) : null;
};

export default Modal;
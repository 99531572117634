import styled from 'styled-components';

export const Wrapper = styled.div`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 700;
	width: inherit;
	outline: 0;
`;

export const Backdrop = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.3);
	z-index: 500;
`;

export const StyledModal = styled.div`
	z-index: 100;
	background: white;
	position: relative;
	margin: auto;
	border-radius: 8px;
`;

export const Header = styled.div`
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 1rem;
	background-color: #80764B;

`;

export const HeaderText = styled.div`
	color: #fff;
	font-size: 1.5rem;
	font-weight: 700;
	font-family: 'Lato', sans-serif;
	text-align: center;
	margin-top: 1rem;
	margin-left: auto;
	margin-right: auto;
	align-self: center;
`;

export const Content = styled.div`
	padding: 10px;
	max-height: 30rem;
	overflow-x: hidden;
	overflow-y: auto;
`;
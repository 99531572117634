import RoutePath from "../models/route-path.model";
import Login from "../pages/login";


// ----------------------------------------------------------------------------
//         Constants
// ----------------------------------------------------------------------------
const publicRoutes: RoutePath[] = [
  { path: '/login/:workstationId/:cellId/', exact: true, component: Login },
];

export default publicRoutes;

import FieldLogin from "../../models/fieldLogin.model";
import * as Styled from "./styled";


// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
const SmallCardCell = ({ label, labelAux, value }: FieldLogin) => (
  <Styled.Box>
    <Styled.BoxLabel>
      { label }
    </Styled.BoxLabel>
    <Styled.BoxLabelAux>
      { labelAux }
    </Styled.BoxLabelAux>
    <Styled.BoxValue>
      { value }
    </Styled.BoxValue>
  </Styled.Box>
);

export default SmallCardCell;

import React from "react";
import * as Styled from "./styled";

const LogoTV = () => (
  <Styled.LogoContainerTV>
    <img src="/viaaroma.svg" alt="Logo Via Aroma"/>
  </Styled.LogoContainerTV>
);

export default LogoTV;

import styled from 'styled-components';


// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

export const FieldTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding-top: 25px;
`;

import styled from 'styled-components'

// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
export const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: -20px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.60));
  opacity: 0.8;
`;
